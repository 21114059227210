<template>
  <v-dialog v-if="application" v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn text class="primary--text" v-on="on">
        <v-icon class="mr-1">settings_backup_restore</v-icon>
          Restart
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Restart an Application</span>
      </v-card-title>
      <v-card-text>
        Are you sure want to restart an application
        <strong>{{ application.name }}</strong
        >?
        <br/>
        <p>
          It will trigger application restart on all devices.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn class="primary" text @click.native="dialog = false"
          >Cancel</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn class="primary--text" text @click.native="restartApplication()"
          >Restart</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    }
  },

  props: {
    application: Object
  },

  computed: {
    error() {
      return this.$store.state.application.error
    },
    projectId() {
      return this.$route.params.project
    },
    selectedNamespaceId() {
      return this.$route.params.namespace
    }
  },

  methods: {
    restartApplication() {
      this.$store.dispatch('RestartApplication', this.application).then(() => {
        if (this.error == null) {
          this.dialog = false
        }
      })
    }
  }

}
</script>
